import React from "react";

const SingleProcessBlock = ({ list }) => {
  return (
    <>
      {list.map((item) => (
        <div
          className="relative mb-12 rounded-2.5xl border border-jacarta-100 bg-white p-12 transition-shadow hover:shadow-xl dark:border-jacarta-700 dark:bg-jacarta-700 lg:w-1/3"
          key={item.id}
        >
          {item.icon}
          <span className="mb-2 inline-block text-2xs font-medium text-accent"></span>
          <h3 className="mb-2 block font-display text-xl font-medium group-hover:text-accent dark:text-white transition transition-colors">
            {item.title}
          </h3>
          <p className="dark:text-jacarta-300">{item.text}</p>

          <div className="absolute -bottom-6 left-1/2 inline-flex h-12 w-12 -translate-x-1/2 items-center justify-center rounded-full bg-light-base dark:bg-jacarta-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="20"
              height="20"
              className="fill-accent dark:fill-accent-lighter"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path>
            </svg>
          </div>
        </div>
      ))}
    </>
  );
};

export default SingleProcessBlock;
